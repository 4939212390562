.about-us-hero {
    height: 250px;
}

.about-us-body {
    margin-bottom: 30px;
}

.about-us-title {
    font-family: 'Carter One', cursive;
    margin-left: 10px;
    margin-top: 30px;
}

.about-us-text {
    padding: 0px;
    margin: 10px;
}

.testemonials-container {
    margin-top: 20px;
}

.testemonial-img-wrapper {
    display: flex;
    justify-content: center;
    width: 100vw;
}

.testemonial-img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    /* position: relative; */
    /* bottom: 75px; */
}

.testemonial-text-wrapper {
    padding: 10px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
    .about-us-hero {
        height: 250px;
    }

    .testemonials-container {
        display: flex;
        margin-left: 30px;
        margin-right: 30px;
    }

    .testemonial-img-wrapper {
        width: auto;
        align-items: center;
    }

    .about-us-title, .about-us-text {
        margin-left: 30px;
    }

    .about-us-text {
        max-width: 500px;
    }
}


@media only screen and (min-width: 992px) {
    .about-us-text {
        max-width: 600px;
    }
}


@media only screen and (min-width: 1200px) {
    .about-us-hero {
        height: 350px;
    }

    .about-us-body {
        margin-top: 50px;
        margin-left: 200px;
        margin-right: 200px;
        margin-bottom: 50px;
    }
}

@media only screen and (min-width: 1700px) {
    .about-us-hero {
        height: 450px;
    }

    .about-us-body {
        margin-top: 50px;
        margin-left: 400px;
        margin-right: 400px;
        margin-bottom: 50px;
    }
}
