.coaster, .coaster-img {
    transition: 0.3s;
    border-radius: 50%;
}

.coaster {
    background-color: black;
    position: relative;
    text-align: center;
    margin: 0px;
}

.coaster-img {
    opacity: 1;
    object-fit: cover;
    box-shadow: 4px 5px 15px #111;
}

.coaster-prompt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    font-family: 'Faster One', cursive;
    color: #ccffff;
}

.coaster-with-link:hover .coaster-img {
    opacity: 0;
}

.coaster-with-link:hover .coaster-prompt {
    opacity: 1;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
