/* Carousel CSS */
.carousel-item {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    height: 50vh;
    background: black;
}

.carousel-caption {
    top: 50%;
    transform: translateY(-50%);
    bottom: initial;
    text-shadow: 2px 2px 3px #4d4d4d;
}

.caption-paragraph {
    text-shadow: 1px 1px 1px #111;
}

.carousel-item img {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 50vh;
    object-fit: cover;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
    .caption-header {
        font-size: 40px;
    }
}


@media only screen and (min-width: 576px) {
    .caption-header {
        font-size: 40px;
    }
}


@media only screen and (min-width: 768px) {
    .caption-header {
        font-size: 50px;
    }
}


@media only screen and (min-width: 992px) {
    .caption-header {
        font-size: 60px;
    }
}


@media only screen and (min-width: 1200px) {
    .carousel-item {
        height: 75vh;
    }
    .carousel-item img {
        min-height: 75vh;
    }    
    .caption-header {
        font-size: 75px;
    }
    .caption-paragraph {
        font-size: 20px;
    }
}
