/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
    .group-cards-container {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100vw;
    }
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
