.upload-modal-warning {
    color: red;
    margin: 0px;
    padding: 0px;
    margin-bottom: 15px;
}

.pic-upload-preview-container {
    display: flex;
    justify-content: center;
    margin: 10px;
}

.pic-upload-modal-profile-preview {
    width: 30vh;
    height: 30vh;
    border-radius: 50%;
    object-fit: cover;
    object-position: 0px 0px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
