.start-group-form-container {
    width: 90%;
}

.start-group-form-cover-img {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.start-group-time-picker, .start-group-date-picker {
    width: 100%;
    height: 40px;
    margin: 0px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 0px;
    border-color: rgb(224, 224, 224);
    border-style: solid;
}

.start-group-date-wrapper, 
.start-group-date-wrapper > div.react-datepicker-wrapper, 
.start-group-date-wrapper > div > div.react-datepicker__input-container
.start-group-date-wrapper > div > div.react-datepicker__input-container input {
    width: 100%;
}

.start-group-input-group {
    padding: 0px;
    margin: 0px;
}  

.focus-char-counter-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    margin: 0px;
    height: 0px;
}

.focus-char-counter {
    color: gray;
    position: relative;
    bottom: 0px;
}

.start-group-submit-wrapper {
    margin-top: 20px;
}

.start-group-submit-error-msg {
    color: rgb(235, 18, 18);
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
    .start-group-form-container {
        width: 70%;
    }      

    .start-group-form-cover-img {
        height: 200px;
    }

    .start-group-form-photo-btn-container {
        display: block;
    }
}


@media only screen and (min-width: 992px) {
    .start-group-form-container {
        width: 60%;
    }    
}


@media only screen and (min-width: 1200px) {
    .start-group-form-container {
        width: 50%;
    }     
}
