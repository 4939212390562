/* Screen size specs */

.playground-page {
    margin: 40px;
}

.playground-test-container {
    border-style: solid;
    border-width: thin;
    border-radius: 10px;
    padding: 10px;
    max-width: 600px;
    margin-bottom: 20px;
}

.playground-btn-wrapper {
    width: 250px;
}

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
