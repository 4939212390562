.your-groups-section-text-container {
    /* background-image: linear-gradient(to right, rgb(89, 142, 167), rgb(139, 82, 82)); */
    /* background-color: rgb(80, 80, 80); */
    /* color: white; */
    padding: 10px;
    /* margin-top: 50px; */
}

.your-groups-section-title {
    font-size: 25px;
    font-weight: bold;
}

.your-groups-section-text {
    margin: 0px;
}

.your-groups-group-card {
    border-bottom-style: solid;
    border-bottom-color: rgb(202, 202, 202);
    border-bottom-width: thin;

}

.your-groups-body {
    margin-bottom: 35px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
    .your-groups-body {
        margin-top: 15px;
    }

    .your-groups-section-title {
        font-size: 40px;
    }

    .your-groups-card-container {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        position: relative;
    }

    .your-groups-section-text-container {
        padding: 20px;
        max-width: 550px;
    }
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
    .your-groups-section-title {
        font-size: 50px;
    }
}

@media only screen and (min-width: 1700px) {
    .your-groups-body {
        margin-left: 300px;
        margin-right: 300px;
    }
}
