#coaster-in-leaderGrid .coaster-prompt {
    font-size: 30px;
}

#leader-grid {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    justify-content: flex-start;
}

#leader-col {
    margin-bottom: 20px;
}

.coaster-caption {
    margin-top: 15px;
}

/* Screen size specs */
@media only screen and (max-width: 576px) {
    #coaster-in-leaderGrid, #coaster-in-leaderGrid .coaster-img {
        /* size of coasters in grid */
        width: 40vw;
        height: 40vw;
    }    
    #coaster-in-leaderGrid .coaster-prompt {
        font-size: 20px;
    }
    .coaster-caption {
        font-size: 15px;
    }
}


@media only screen and (min-width: 576px) {
    #coaster-in-leaderGrid, #coaster-in-leaderGrid .coaster-img {
        /* size of coasters in grid */
        width: 20vw;
        height: 20vw;
    }    
    #coaster-in-leaderGrid .coaster-prompt {
        font-size: 20px;
    }
    .coaster-caption {
        font-size: 15px;
    }
}


@media only screen and (min-width: 768px) {
}


@media only screen and (min-width: 992px) {
    #coaster-in-leaderGrid, #coaster-in-leaderGrid .coaster-img {
        /* size of coasters in grid */
        width: 14vw;
        height: 14vw;
    }    
    #coaster-in-leaderGrid .coaster-prompt {
        font-size: 20px;
    }
    .coaster-caption {
        font-size: 15px;
    }
}


@media only screen and (min-width: 1200px) {
    #coaster-in-leaderGrid, #coaster-in-leaderGrid .coaster-img {
        /* size of coasters in grid */
        width: 10vw;
        height: 10vw;
    }    
    #coaster-in-leaderGrid .coaster-prompt {
        font-size: 20px;
    }
    .coaster-caption {
        font-size: 18px;
    }
}

@media only screen and (min-width: 1800px) {
    #coaster-in-leaderGrid, #coaster-in-leaderGrid .coaster-img {
        /* size of coasters in grid */
        width: 10vw;
        height: 10vw;
    }    
    #coaster-in-leaderGrid .coaster-prompt {
        font-size: 20px;
    }
    .coaster-caption {
        font-size: 20px;
    }
}
