.profile-page-container {
    padding-top: 30px;
    /* margin-bottom: 80px; */
}

.profile-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.profile-img-background, .profile-img {
    height: 125px;
    width: 125px;
    border-radius: 50%;
}

.profile-img-background {
    background-color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-img {
    object-fit: cover;
    object-position: 50% 15%;
    position: absolute;
}

.profile-img-placeholder-text {
    font-size: 60px;
    font-weight: bold;
    color: rgb(206, 206, 206)
}

.profile-img-edit-hover {
    font-size: 40px;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 0);
    height: 125px;
    width: 125px;
    border-radius: 50%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transition-duration: 0.2s;
    border-style: none;
}

.profile-img-edit-hover:hover {
    background-color: rgba(219, 219, 219, 0.616);
    color: rgb(105, 105, 105);
}

.profile-name {
    font-size: 25px;
    margin-top: 5px;
    font-weight: bold;
}

.profile-username {

}

.profile-table-container {
    padding: 15px;
}

.profile-table-text {
    font-size: 11px;
    border-style: none;
}

.profile-left-col {
    width: 100px;
}

.profile-edit-btn-container {
    display: flex;
    justify-content: center;
}

.profile-edit-btn-wrapper {
    width: 100px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
    .profile-page-container {
        padding-top: 0px;
        margin: 0px;
        padding-left: 150px;
        padding-right: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 85vh;
    }

    .profile-img-background, .profile-img, .profile-img-edit-hover {
        width: 175px;
        height: 175px;
    }

    .profile-table-text {
        font-size: 14px;
    }

    .profile-left-col {
        width: 120px;
    }
}


@media only screen and (min-width: 992px) {
    .profile-page-container {
        padding-left: 250px;
        padding-right: 250px;
    }
}


@media only screen and (min-width: 1200px) {
    .profile-page-container {
        padding-left: 350px;
        padding-right: 350px;
    }
}
