/* Group Cards CSS */

#card-grid {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
}

#card-col {
    margin-bottom: 20px;
    /* margin-top: 20px; */
}

#card-card, .card-img {
    height: 120px;
    width: 20vw;
    min-width: 200px;
    border-radius: 20px;
}

#card-card, .card-text, .card-img, .card-prompt {
    transition: 0.3s;
}

#card-card {
    position: relative;
    text-align: center;
    color: white;
    background-color: black;
}

.card-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    font-family: 'Baloo Da 2', monospace;
    color: white;
}

.card-img {
    box-shadow: 3px 4px 8px #111;
    object-fit: cover;
    opacity: 0.7
}

.card-prompt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    opacity: 0;
    font-family: 'Faster One', cursive;
    color: #ffd8ff;
}

#card-card:hover .card-prompt {
    opacity: 1;
}

#card-card:hover .card-text {
    opacity: 0;
}

#card-card:hover .card-img {
    opacity: 0;
}
/* Screen size specs */

@media only screen and (max-width: 576px) {
    #card-card, .card-img {
        height: 200px;
        width: 100vw;
        min-width: 200px;
        border-radius: 0px;
    }

    #card-col, #card-grid {
        margin: 0px;
        padding: 0px;
    }

    .card-img {
        box-shadow: 0px 0px 0px;
    }
}


@media only screen and (min-width: 576px) {
    #card-card, .card-img {
        height: 160px;
        width: 44vw;
        border-radius: 20px;
    }
}


@media only screen and (min-width: 768px) {
    #card-card, .card-img {
        height: 160px;
        width: 44vw;
        border-radius: 20px;
    }
}


@media only screen and (min-width: 992px) {
    #card-card, .card-img {
        height: 120px;
        width: 22vw;
        border-radius: 20px;
    }
}


@media only screen and (min-width: 1200px) {
    #card-card, .card-img {
        height: 140px;
        width: 21vw;
        border-radius: 20px;
    }
}
