/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
}


@media only screen and (min-width: 992px) {
    .embed-responsive {
        width: 80vw;
        margin-left: auto;
        margin-right: auto;
    }
}


@media only screen and (min-width: 1200px) {
    .embed-responsive {
        width: 70vw;
        margin-left: auto;
        margin-right: auto;
    }
}
