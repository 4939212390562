.group-card {
  width: 100vw;
}

.group-card-container {
  position: relative;
}

.group-card-img-container {
  height: 150px;
}

.group-card-img {
  object-fit: cover;
  width: 100%;
  height: 150px;
  object-position: 50% 60%;
}

.group-card-leader-img-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 85px;
}

.group-card-leader-img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  object-position: 0% 20%;
  position: relative;
  bottom: 75px;
  box-shadow: 0px 0px 25px rgb(68, 68, 68);
}

.group-card-info-container {
  margin-bottom: 15px;
}

.group-card-focus-container {
  margin-bottom: 20px;
  margin-left: 20px;
}

.group-card-focus {
  font-weight: bold;
  font-size: 30px;
  font-family: 'Carter One', cursive;
}

.group-card-other-info-container {
  padding: 20px;
}

.group-card-text {
  margin: 0px;
  padding: 0px;
  margin-bottom: 10px;
}

.group-card-description, .group-card-other-info-container {
  min-height: 100px;
}

.group-card-description-title {
  font-size: 12px;
  color: gray;
  margin-left: 10px;
  margin-top: 20px;
}

.group-card-description-text {
  margin: 20px;
}

.group-card-scroll-instruction {
  display: none;
}

.group-card-info-fields {
  font-size: 14px;
  margin-left: 8px;
}

.group-card-btn-wrapper-wrapper {
  display: flex;
  justify-content: center;
}

/* Button Animation */
.group-card-btn-text {
  font-family: 'Carter One', cursive;
  font-size: 16px;
}

.custom-btn {
  width: 130px;
  height: 40px;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
}

.btn-6 {
  background: rgb(247, 150, 192);
  background: radial-gradient(
    circle,
    rgba(247, 150, 192, 1) 0%,
    rgba(118, 174, 241, 1) 100%
  );
  line-height: 42px;
  padding: 0;
  border: none;
}
.btn-6 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-6:before,
.btn-6:after {
  position: absolute;
  content: "";
  height: 0%;
  width: 1px;
  box-shadow: -1px -1px 20px 0px rgba(255, 255, 255, 1),
    -4px -4px 5px 0px rgba(255, 255, 255, 1),
    7px 7px 20px 0px rgba(0, 0, 0, 0.4), 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
}
.btn-6:before {
  right: 0;
  top: 0;
  transition: all 500ms ease;
}
.btn-6:after {
  left: 0;
  bottom: 0;
  transition: all 500ms ease;
}
.btn-6:hover {
  background: transparent;
  color: #76aef1;
  box-shadow: none;
}
.btn-6:hover:before {
  transition: all 500ms ease;
  height: 100%;
}
.btn-6:hover:after {
  transition: all 500ms ease;
  height: 100%;
}
.btn-6 span:before,
.btn-6 span:after {
  position: absolute;
  content: "";
  box-shadow: -1px -1px 20px 0px rgba(255, 255, 255, 1),
    -4px -4px 5px 0px rgba(255, 255, 255, 1),
    7px 7px 20px 0px rgba(0, 0, 0, 0.4), 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
}
.btn-6 span:before {
  left: 0;
  top: 0;
  width: 0%;
  height: 0.5px;
  transition: all 500ms ease;
}
.btn-6 span:after {
  right: 0;
  bottom: 0;
  width: 0%;
  height: 0.5px;
  transition: all 500ms ease;
}
.btn-6 span:hover:before {
  width: 100%;
}
.btn-6 span:hover:after {
  width: 100%;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}

@media only screen and (min-width: 576px) {
}

@media only screen and (min-width: 767px) {

  .group-card-description, .group-card-other-info-container {
    border-bottom-style: solid;
    border-width: thin;
    border-color: rgb(223, 223, 223);
    height: 155px;
    margin-bottom: 0px;
    overflow: scroll;  
  }

  .group-card-scroll-instruction {
    display: block;
    margin: 20px;
    margin-top: 5px;
    font-size: 12px;
    color: rgb(143, 143, 143);
  }

  .group-card-container {
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 3px 3px 10px gray;
  }
  .group-card {
    width: 44.5vw;
    margin: 20px;
  }
}

@media only screen and (min-width: 992px) {
  .group-card-container {
  }

  .group-card {
    width: 29vw;
  }
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1700px) {
  .group-card {
    width: 19vw;
  }
}
