.text-wrapper {
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 50px;
  margin-bottom: 50px;
}

p {
  font-size: 14px;
}

span {
  font-size: 14px;
}

h3 {
  font-size: 30px;
}

.form-item-description {
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  color: gray;
  margin-bottom: 5px;
}

.form-label {
  margin-top: 15px;
}

/* #root {
  color: white;
  background-color: black;
} */

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
}


@media only screen and (min-width: 992px) {
  .text-wrapper {
      padding-left: 30px;
      padding-right: 20vw;
  }
  
  p {
      font-size: 15px;
  }
  
  h3 {
      font-size: 40px;
  }
}


@media only screen and (min-width: 1200px) {
  .text-wrapper {
      padding-left: 30px;
      padding-right: 40vw;
      margin-top: 50px;
      margin-bottom: 50px;
  }
  
  p {
      font-size: 15px;
  }
  
  h3 {
      font-size: 50px;
  }
}
