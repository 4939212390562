.create-account-page {
    height: fit-content;
    min-height: 100vh;
    width: 100vw;
    position: relative;
    background-color: black;
    background-attachment: fixed;
    background-size: cover;
    display: flex;
    justify-content: center;
}

.create-account-card {
    height: 100vh;
    width: 100vw;
    background-color: white;
}

.create-account-card-wrapper {
    margin: 20px;
    margin-top: 40px;
    position: relative;
    padding-bottom: 8px;
}

.create-account-header {
    text-align: center;
    margin-bottom: 40px;
}

.input-group, .create-account-button-wrapper, .create-account-links{
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 30px;
}

.btn {
    width: 100%;
}

p {
    margin-left: 10px;
}

.create-account-lower-p {
    margin-top: 30px;
}

#create-account-already-have {
    margin-top: 30px;
    padding-left: 10px;
    margin-bottom: 40px;
}

#create-account-already-have span {
    margin-right: 5px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
    .input-group, .create-account-button-wrapper, .create-account-links{
        padding-left: 40px;
        padding-right: 40px;
    }

    .create-account-card-wrapper {
        margin-top: 25vh;
    }

    #create-account-already-have {
        margin-top: 30px;
        padding-left: 30px;
    }
}


@media only screen and (min-width: 992px) {
    .create-account-card-wrapper {
        margin-top: 40px;
    }
    .create-account-card {
        height: fit-content;
        width: 600px;
        box-shadow: 0px 0px 50px black;
        border-radius: 20px;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}


@media only screen and (min-width: 1200px) {
}
