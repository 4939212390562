/* Screen size specs */

.data-table-options-container {
    display: flex;
}

.data-table-option-btn-container {
    margin-right: 5px;
}

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
