#profile-coaster-container {
    position: relative;
}

#coaster-moveable {
    position: absolute;
    margin: auto;
    top: 0; left: 0; bottom: 0; right: 0;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
    #coaster-moveable, #coaster-moveable .coaster-img {
        width: 150px;
        height: 150px;
    }    
    .leader-container {
        margin-top: 100px;
    }
    #leaders-hero, #leaders-hero .hero-img {
        height: 45vh;
    }
}


@media only screen and (min-width: 576px) {
    #coaster-moveable, #coaster-moveable .coaster-img {
        width: 150px;
        height: 150px;
    }    
    .leader-container {
        margin-top: 100px;
    }
    #leaders-hero, #leaders-hero .hero-img {
        height: 40vh;
    }
}


@media only screen and (min-width: 768px) {
    #coaster-moveable {
        margin-left: 100px;
    }
    .leader-container {
        margin-top: 100px;
    }
    #leaders-hero, #leaders-hero .hero-img {
        height: 30vh;
    }
}


@media only screen and (min-width: 992px) {
    #coaster-moveable {
        margin-left: 100px;
    }
    .leader-container {
        margin-top: 100px;
    }
    #leaders-hero, #leaders-hero .hero-img {
        height: 30vh;
    }
}


@media only screen and (min-width: 1200px) {
}
