.start-group-page-container {
    display: flex;
    justify-content: center;
    width: 100vw;
    margin-bottom: 30px;
    margin-top: 30px;
}

.start-group-form-container {
    width: 90%;
}

.start-group-hero {
    height: 150px;
}

.start-group-title {
    font-size: 30px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
    .start-group-form-container {
        width: 70%;
    }      

    .start-group-hero {
        height: 250px;
    }    
}


@media only screen and (min-width: 992px) {
    .start-group-form-container {
        width: 60%;
    }    
}


@media only screen and (min-width: 1200px) {
    .start-group-form-container {
        width: 50%;
    }     

    .start-group-hero {
        height: 350px;
    }    
}
